<template>
   <div>
      <header>
         <img
            src="../assets/banner-app.png"
            alt=""
         />
      </header>
      <main>
         <div class="text">
            <h1
               style="
                  text-align: center;
               "
            >
               <strong
                  >隐私政策</strong
               >
            </h1>
            <p>
               南棠跨境电商（“我们”）非常重视对您用户的个人隐私和个人信息安全保护。您在使用我们的产品或服务时，我们可能会收集和使用您的相关信息。有时候我们需要某些信息才能为您提供您请求的服务，我们希望通过本隐私政策声明向您解释了这些情况下的数据收集和使用、保存、共享和转让等情况。
               &nbsp;本隐私政策声明适用于「南棠跨境电商」的所有相关服务，随着「南棠跨境电商」服务范围的扩大，隐私声明的内容可由「南棠跨境电商」随时更新，且毋须另行通知。更新后的隐私声明一旦在网页上公布即有效代替原来的隐私声明。如前述修改涉及您在本政策下权利的实际减损，我们将在变更生效前，通过页面显着位置提示。在该种情况下，若您继续使用我们的服务，即表示同意受修订后的版本的约束。请您在向南棠跨境电商提交个人信息或隐私信息之前，阅读、了解并同意本政策和任何补充政策。本隐私政策要点如下：
            </p>
            <p>
               ·
               &nbsp;我们会逐一说明我们对个人信息收集、使用、保护等处理的情况，以便您能够了解个人信息的概况。
            </p>
            <p>
               ·
               &nbsp;当您注册南棠跨境电商帐号和使用我们的服务时，我们会根据您的同意和提供服务的需要，收集您的姓名、性别、年龄、电话号码、社交帐号、位置信息和日志信息等个人信息。
            </p>
            <p>
               ·
               &nbsp;除了产品的核心功能外，南棠跨境电商提供一些附加功能来提升用户体验，包括：消息设置、推送通知设置等。当您使用南棠跨境电商的附加功能时，我们不会额外收集您的个人信息，除非根据本政策告知并取得您的同意。
            </p>
            <p>
               ·
               &nbsp;当您在未登录状态下时，将显示为「游客」。为了更好地为您提供便捷流畅的社区体验，「游客」同样可以进行查看文章等基础行为。为了方便您查阅这些过往信息，我们需要获取您的设备号(IMEI码)以便记录存档。
            </p>
            <p>
               ·
               &nbsp;目前，除法律法规、法律程序、诉讼或政府主管部门强制性要求外，南棠跨境电商不会主动公开披露您的个人信息，如果存在其他需要公开披露个人信息的情形，我们会征得您的明示同意。同时，我们保证披露采取符合法律和业界标准的安全防护措施。
            </p>
            <p>
               ·
               &nbsp;您可以通过本隐私政策所列的途径访问、更正或删除您的个人信息，也可以与我们取得联系。
            </p>
            <p>
               ·
               &nbsp;您使用或继续使用我们的服务，即意味着同意我们按照本《隐私政策》收集、使用、储存、共享、转让和公开披露您的相关信息。
            </p>
            <h2>
               <strong
                  >一、我们收集的个人信息</strong
               >
            </h2>
            <p>
               个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别自然人个人身份的各种信息，包括但不限于自然人的姓名、出生日期、身份证件号码、个人生物识别信息、住址、电话号码等。此类信息会在您注册和使用我们的服务时被收集，南棠跨境电商根据合法、正当、必要原则，仅会出于本政策所述以下目的收集和使用您的个人信息：
            </p>
            <p>
               1、您直接提供和我们自动采集的个人信息
            </p>
            <p>
               ·
               &nbsp;注册信息。您使用南棠跨境电商提供的服务，可以注册并登录经注册的南棠跨境电商帐号。此时，您需要向我们提供以下信息：帐号名称、头像（如有）和手机号码等。提供上述信息并同意注册协议和本政策后，您可以使用南棠跨境电商的核心业务功能，包括：浏览南棠跨境电商平台内的内容、发布提问、回答、评论、评价等。在您注册南棠跨境电商帐号时，我们会使用手机号码对您进行实名验证，如您拒绝提供手机号码或进行实名验证，您仍可浏览部分南棠跨境电商平台内的内容，但将无法使用提问、发布内容、评论、评价等信息发布服务。
            </p>
            <p>
               ·
               &nbsp;附加信息。当您使用南棠跨境电商附加业务功能时，为满足向您提供该产品和服务之目的，除注册信息外，您还需要进一步向我们提供包括但不限于您的个人身份信息、位置信息及其他为您提供服务所必需的信息，如果您不使用特定产品和服务，则无需提供相关信息（以下将详细阐明）。
            </p>
            <p>
               ·
               &nbsp;位置信息。当您开启设备定位功能并使用我们基于位置提供的相关服务时，我们会收集有关您位置的信息。该信息属于敏感信息，拒绝提供该信息仅会使您无法使用与位置信息相关的功能，但不影响您正常使用南棠跨境电商的其他功能。
            </p>
            <p>
               ·
               &nbsp;关键词信息。当您使用南棠跨境电商提供的搜索服务时，我们会收集您的查询关键字信息、设备信息等，为了提供高效的搜索服务，这些信息有部分会暂时存储在您的本地存储设备之中。该等关键词信息通常无法单独识别您的个人身份，不属于您的个人信息，不在本政策的限制范围内。只有当您的搜索关键词信息与您的其他信息互有联系并可以识别您的个人身份时，则在结合使用期间，我们会将您的搜索关键词信息作为您的个人信息，与您的搜索历史记录一同按照本政策对其进行处理与保护。
            </p>
            <p>
               ·
               &nbsp;真实身份信息。当您使用南棠跨境电商提供的身份认证服务时，我们会收集您的姓名、身份证号、职业、有关身份证明等信息。如果您不提供这些信息，我们将无法提供相关服务。
            </p>
            <p>
               ·
               &nbsp;联系方式信息。当您向南棠跨境电商进行帐号申诉或参与营销活动时，为了方便与您联系或帮助您解决问题，我们会收集并记录您的姓名、手机号码、电子邮件及其他联系方式等个人信息。如您拒绝提供上述信息，我们可能无法向您及时反馈投诉结果或向您邮寄礼品（如有）。
            </p>
            <p>
               ·
               &nbsp;用户共享的信息。当您在南棠跨境电商中使用第三方提供的服务时，您同意南棠跨境电商允许该第三方收集您的帐号名称、头像及其他提供服务所必须的个人信息。如果您拒绝第三方在提供服务时收集上述信息，将可能导致您无法在南棠跨境电商中使用该第三方服务。
            </p>
            <p>
               2、我们可能从第三方获取您个人信息的情形
            </p>
            <p>
               您也可以使用第三方帐号（如微信）登录南棠跨境电商。此时您可选择授权南棠跨境电商在符合相关法律和法规规定的前提下读取您在该第三方平台上登记、公布、记录的公开信息（包括但不限于昵称、头像、关注用户列表等）。请您仔细阅读第三方合作服务的用户协议或隐私政策。对于我们要求但第三方无法提供的个人信息，我们仍可以要求您补充提供。南棠跨境电商可能从第三方获取您的上述信息的目的是为了记住您作为南棠跨境电商用户的登录身份，并向您提供个性化推荐，以便于南棠跨境电商向您提供更优质的产品服务。您可以在使用第三方帐号登录南棠跨境电商时选择授权南棠跨境电商可获取的个人信息的范围，或在使用南棠跨境电商的过程中通过【帐号与安全-社交帐号绑定】设置拒绝或管理南棠跨境电商获取您个人信息的权限。但请注意，如果停止某些权限，您有可能无法享受最佳的服务体验，某些服务也可能无法正常使用。
            </p>
            <p>
               3、征得授权同意的例外
            </p>
            <p>
               根据相关法律法规的规定，以下情形中收集您的个人信息无需征得您的授权同意：
            </p>
            <p>
               （1）涉及国家安全与利益、社会公共利益；
            </p>
            <p>
               （2）与犯罪侦查有关的相关活动；
            </p>
            <p>
               （3）出于维护您或他人的生命财产安全但在特殊情况下无法获得您的及时授权；
            </p>
            <p>
               （4）从其他合法公开的渠道中收集您的个人信息；
            </p>
            <p>
               （5）法律法规规定的其他情形。
            </p>
            <h2>
               <strong
                  >二、</strong
               ><strong
                  >南棠跨境电商</strong
               ><strong
                  >会如何使用您的个人信息</strong
               >
            </h2>
            <p>
               我们严格遵守法律法规及隐私政策之约定，将收集的信息用于以下途径。若我们超出以下范畴，我们将会再次向您说明并取得您的同意：
            </p>
            <p>
               ·
               &nbsp;为您提供个性化服务。
            </p>
            <p>
               ·
               &nbsp;帮助我们设计新服务，提升现有服务体验。
            </p>
            <p>
               ·
               &nbsp;在我们提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性。
            </p>
            <p>
               ·
               &nbsp;向您发送您可能感兴趣的产品和服务的信息；向您提供与您更加相关的广告以替代普遍投放的广告；邀请您参与南棠跨境电商活动和市场调查。
            </p>
            <p>
               ·
               &nbsp;开展内部审计、数据分析和研究，以更好地改善我们的产品、服务及与用户之间的沟通。
            </p>
            <h2>
               <strong
                  >三、我们会如何使用
                  &nbsp;Cookie
                  &nbsp;和同类技术</strong
               >
            </h2>
            <p>1、Cookie</p>
            <p>
               您使用我们的服务时，我们会在您的计算机或移动设备上存储名为
               &nbsp;Cookie
               &nbsp;的小数据文件。Cookie
               &nbsp;通常包含标识符、站点名称以及一些号码
               和字符。我们使用该等信息判断注册用户是否已经登录，提升服务/产品质量及优化用户体验。如您不希望个人信息保存在
               Cookie
               中，您可对浏览器进行配置，选择禁用
               Cookie
               功能。禁用
               Cookie
               功能后，可能影响您访问南棠跨境电商或不能充分取得南棠跨境电商提供的服务。
               南棠跨境电商不会将
               Cookie
               用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookie。您可以清除计算机上保存的所有
               Cookie，大部分网络浏览器都设有阻止
               Cookie 的功能。
            </p>
            <p>
               2、设备权限调用
            </p>
            <p>
               南棠跨境电商在提供服务的过程中，可能需要您开通一些设备权限，例如通知、相册、相机、手机通讯录、蓝牙等访问权限。您也可以在设备的设置功能中随时选择关闭部分或者全部权限，从而拒绝南棠跨境电商收集相应的个人信息。在不同设备中，权限显示方式及关闭方式可能有所不同，具体请参考设备及系统开发方说明或指引。
            </p>
            <h2>
               <strong
                  >四、我们会</strong
               ><strong
                  >如何</strong
               ><strong
                  >共享、转让、公开披露您的个人信息</strong
               >
            </h2>
            <p>1、共享</p>
            <p>
               南棠跨境电商不会在未经您同意或授权的情况下将您的个人信息提供给第三方。但是，经您确认同意，南棠跨境电商可在以下情况下共享您的个人信息：
            </p>
            <p>
               ·
               在获取同意的情况下共享：获得您的明确同意后，南棠跨境电商会与其他方共享您的个人信息。
            </p>
            <p>
               ·
               共享给南棠跨境电商的关联公司：在本政策声明的使用目的范围内，您的个人信息可能会与南棠跨境电商的关联公司共享。作为一项政策，我们只会共享必要的信息。关联公司如要改变个人信息的处理目的，将再次征求您的授权同意。
            </p>
            <p>
               ·
               共享给授权合作伙伴：南棠跨境电商可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。例如，南棠跨境电商向您寄送礼品（如有）时，南棠跨境电商必须与物流服务提供商共享您的个人信息才能安排寄送，或者安排合作伙伴向您提供服务。我们仅会出于特定、明确而合法的目的处理您的个人信息，并且只会共享提供服务所必要的信息。同时，我们会与其签署严格的保密协定，要求他们按照我们的说明、本政策以及其他任何相关的保密和安全措施来处理个人信息。
            </p>
            <p>
               ·
               在法定情形下的共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。
            </p>
            <p>2、转让</p>
            <p>
               我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
            </p>
            <p>
               ·
               在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；
            </p>
            <p>
               ·
               在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
            </p>
            <p>3、公开披露</p>
            <p>
               我们仅会在以下情况下，且采取符合法律和业界标准的安全防护措施的前提下，才会公开披露您的个人信息：
            </p>
            <p>
               ·
               获得您的明确同意；
            </p>
            <p>
               ·
               基于法律法规、法律程序、诉讼或政府主管部门强制性要求。但我们保证，在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件
            </p>
            <h2>
               <strong
                  >五、如何管理您的个人信息</strong
               >
            </h2>
            <p>
               我们将尽一切可能采取合法适当的技术手段，保证收集到的有关于您的个人信息的准确性，并保证及时进行更新。
            </p>
            <p>
               您也可以通过设置页面，按照南棠跨境电商的相关政策及提示，对您的个人信息进行访问、更正及删除。
            </p>
            <h2>
               <strong
                  >六、</strong
               ><strong
                  >推广</strong
               ><strong
                  >服务</strong
               >
            </h2>
            <p>
               我们可能使用您的个人信息，通过我们的站内私信、电子邮件或其他方式向您提供或推广我们或第三方的商品和服务。
            </p>
            <h2>
               <strong
                  >七、我们会如何</strong
               ><strong
                  >保护</strong
               ><strong
                  >和保存您的个人信息</strong
               >
            </h2>
            <p>
               南棠跨境电商重视个人信息的安全。我们采取法律法规及互联网业内标准做法来保护您的个人信息，防止信息遭到未经授权访问、披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。
            </p>
            <p>
               我们会采取一切合理可行的措施，确保未收集与南棠跨境电商提供的服务无关的个人信息。我们会尽全力保护您的个人信息，但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，不可能始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。
            </p>
            <p>
               在发生个人信息安全事件的场合，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。
            </p>
            <p>
               同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
            </p>
            <p>
               我们在中华人民共和国境内收集和产生的个人信息将存储在中华人民共和国境内。如果日后为处理跨境业务，需要向境外机构传输境内收集的相关个人信息的，我们会事先征得您的同意，按照法律、行政法规和相关监管部门的规定执行，并通过签订协议、核查等有效措施，要求境外机构为所获得的个人信息保密。
            </p>
            <p>
               我们仅会在达到本政策所述目的所必需的时限内保存您的个人信息，但为了遵守适用的法律法规、法院判决或裁定、其他有权机关的要求、维护公共利益等目的，我们可能会将个人信息保存时间予以适当延长。
            </p>
            <h2>
               <strong
                  >八、第三方</strong
               ><strong
                  >提供</strong
               ><strong
                  >商及其服务</strong
               >
            </h2>
            <p>
               为确保流畅的浏览体验，您可能会收到来自南棠跨境电商及其合作伙伴外部的第三方（以下简称「第三方」）提供的内容或网络链接。南棠跨境电商对此类第三方无控制权。您可选择是否访问第三方提供的链接、内容、产品和服务。
            </p>
            <p>
               南棠跨境电商无法控制第三方的隐私和个人信息保护政策，此类第三方不受到本政策的约束。您在向第三方提交个人信息之前，请确保您阅读并认可这些第三方的隐私保护政策。
            </p>
            <h2>
               <strong
                  >九、关于</strong
               ><strong
                  >免责</strong
               ><strong
                  >说明</strong
               >
            </h2>
            <p>
               就下列相关事宜的发生，「南棠跨境电商」不承担任何法律责任：
            </p>
            <p>
               ·
               由于您将用户密码告知他人或与他人共享注册帐户，由此导致的任何个人信息的泄漏，或其他非因南棠跨境电商原因导致的个人信息的泄漏；
            </p>
            <p>
               ·
               南棠跨境电商根据法律规定或政府相关政策要求提供您的个人信息；
            </p>
            <p>
               ·
               任何第三方根据南棠跨境电商各服务条款及声明中所列明的情况使用您的个人信息，由此所产生的纠纷；
            </p>
            <p>
               ·
               任何由于黑客攻击、电脑病毒侵入或政府管制而造成的暂时性网站关闭；
            </p>
            <p>
               ·
               因不可抗力导致的任何后果；
            </p>
            <p>
               ·
               南棠跨境电商在各服务条款及声明中列明的使用方式或免责情形。
            </p>
            <h2>
               <strong
                  >十、本政策适用范围</strong
               >
            </h2>
            <p>
               若您不同意修改后的隐私政策，您有权并应立即停止使用南棠跨境电商的服务。如果您继续使用南棠跨境电商的服务，则视为您接受南棠跨境电商对本政策相关条款所做的修改。
            </p>
            <p>
               南棠跨境电商的所有服务均适用本政策。但某些服务有其特定的隐私政策，该等特定的隐私政策更具体地说明南棠跨境电商在该服务中如何处理您的信息。如本政策与特定服务的隐私政策有不一致之处，请以该特定隐私政策为准。
            </p>
            <p>&nbsp;</p>
            <p><br /></p>
         </div>
      </main>
      <footer>
         <img
            src="../assets/2-4.png"
            alt=""
         />
      </footer>
   </div>
</template>

<script>
export default {
   props: {},
   components: {},
   data() {
      return {};
   },
   computed: {},
   watch: {},
   methods: {},
   // 以下是生命周期钩子   注：没用到的钩子请自行删除
   beforeCreate() {},
   /**
    * 组件实例创建完成，属性已绑定，但DOM还未生成，$ el属性还不存在
    */
   created() {},
};
</script>

<style lang="scss" scoped>
header {
   height: 360px;
   width: 100%;
}
main {
   width: 100%;
   display: flex;
   justify-content: center;
   .text {
      width: 70%;
      p {
         text-align: left;
      }
   }
}
</style>
