<template>
   <div>
      <header>
         <div class="block">
            <el-carousel
               trigger="click"
               height="560px"
               autoplay
            >
               <el-carousel-item>
                  <img
                     src="../assets/banner-3.png"
                     alt=""
                  />
               </el-carousel-item>
               <el-carousel-item>
                  <img
                     src="../assets/banner-pic-erp.png"
                     alt=""
                  />
               </el-carousel-item>
               <el-carousel-item>
                  <img
                     src="../assets/2.png"
                     alt=""
                  />
               </el-carousel-item>
               <el-carousel-item>
                  <img
                     src="../assets/3.png"
                     alt=""
                  />
               </el-carousel-item>
               <el-carousel-item>
                  <img
                     src="../assets/4.png"
                     alt=""
                  />
               </el-carousel-item>
            </el-carousel>
         </div>
      </header>
      <main>
         <img
            src="../assets/Snipaste_2024-01-08_16-01-48.png"
            alt=""
         />
         <img
            src="../assets/Snipaste_2024-01-08_16-35-39.png"
            alt=""
         />
         <img
            src="../assets/Snipaste_2024-01-08_16-45-30.png"
            alt=""
         />
         <img
            src="../assets/Snipaste_2024-01-08_16-59-08.png"
            alt=""
         />
         <img
            src="../assets/Snipaste_2024-01-08_17-01-11.png"
            alt=""
         />
         <img
            src="../assets/2-4.png"
            alt=""
         />
      </main>
      <footer>
      </footer>
   </div>
</template>

<script>
export default {
   props: {},
   components: {},
   data() {
      return {};
   },
   computed: {},
   watch: {},
   methods: {},
   // 以下是生命周期钩子   注：没用到的钩子请自行删除
   beforeCreate() {},
   /**
    * 组件实例创建完成，属性已绑定，但DOM还未生成，$ el属性还不存在
    */
   created() {},
};
</script>

<style lang="scss" scoped>
header {
   height: 560px;
   width: 100%;
}
main {
   padding: 0px;
   height: 1000px;
   width: 100%;
   img {
      margin-bottom: -4px;
   }
}
.block img {
   width: 100%;
}
</style>
